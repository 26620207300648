
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { globalCountries, isValidCountryCode } from 'utils/countries';
import  { isValidPhoneNumber } from 'react-phone-number-input/mobile';
import PhoneInput from 'react-phone-number-input';

const useShippingForm = () => {
  const {t} = useTranslation();
  const { control,register, handleSubmit, setValue, formState, watch} = useForm();

  const emailRegister = register("email", {
    required: t("required-email"),
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: t("invalid-email")
    }
  });

  const fullNameRegister = register("full_name", {
    required: t('fullnamerequired'),
    minLength: { value: 3, message: t('name3letters') },
    maxLength: { value: 35, message: t('name35letters') }
  });

  const addressRegister = register("address", {
    required: t("shipping-address-required"),
    minLength: { value: 10, message: t("shipping-address-range-length") },
    maxLength: { value: 45, message: t("shipping-address-range-length") }
  });
  const addressRegister2 = register("address_2", {
    required: t("shipping-address-2-required"),
    minLength: { value: 10, message: t("shipping-address-range-length") },
    maxLength: { value: 45, message: t("shipping-address-range-length") }
  });

  const addressRegister3 = register("address_3", {
    maxLength: { value: 45, message: t('addressrequirednbr') }
  });

  const cityRegister = register("city", {
    required: t("cityrequired"),
    minLength: { value: 3, message: t('city3letters') }
  });


  const PhoneComponent = ({country})=>{
    return (
    <Controller
        name="phone"
        control={control}
        rules={{
            required: t("phonerequired"),
            validate : value => isValidPhoneNumber(value) && isValidCountryCode(value)|| t('phonevalid'),
        }}
        render={({ field }) => (
        <PhoneInput
            className='checkout-phone-input'
            defaultCountry={country}
            countries={globalCountries} 
            placeholder={t('phone')} 
            {...field}
        />
        )}
    />
    )
  }

  const postalCodeRegister = register("postal_code")
  let {errors} = formState
  return { PhoneComponent,handleSubmit, setValue, watch, formState, errors, fullNameRegister, emailRegister, addressRegister, addressRegister2, addressRegister3, cityRegister, postalCodeRegister };
};

export default useShippingForm;

