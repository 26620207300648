import Cookies from "js-cookie";
import axios from "utils/axios";


export const getShipping = async () => {
    let token = Cookies.get('access_token');
    try{
        const response = await axios.get(`shop/shipping`,{
            headers:{Authorization: `Bearer ${token}`}
        }
        );
        if(response.data.success){
            return {userShippingInformation: response.data.userShippingInformation,shipping: response.data.data};
        }
        throw new Error(response.data.error);
    }catch(e){
        throw new Error(e);
    }
};

export const getFreeShipping = async (products) => {
    let token = Cookies.get('access_token');
    let obj = {
        products,
    }
    try{
        const response = await axios.post(`shop/dispatch`,obj,{
            headers:{Authorization: `Bearer ${token}`}
        }
        );
        if(response.data.success){
            return response.data.data;
        }
        throw new Error(response.data.error);
    }catch(e){
        throw new Error(e);
    }
};

export const updateShipping = async (shippingInfo) => {
    let token = Cookies.get('access_token');
    try{
        const response = await axios.post(`shipping`,shippingInfo,{
            headers:{Authorization: `Bearer ${token}`}
        }
        );
        if(response.data.user_id){
            return response.data;
        }
        throw new Error(response.data.error);
    }catch(e){
        throw new Error(e);
    }
};