import Cookies from "js-cookie";
import axios from "utils/axios";


export const validateCoupon = async (code, products) => {
    let token = Cookies.get('access_token');
    try{
        const response = await axios.post(`shop/coupon/${code}`, {products}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`    
            }
        });
        if(response.data.success){
            return response.data;
        }
        throw new Error(response.data.error);
    }catch(e){
        throw new Error(e);
    }
};

export const getAutomaticCoupons = async (products) => {
    let token = Cookies.get('access_token');
    try{
        const response = await axios.post(`shop/automatic/coupon/`, {products}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`    
            }
        });
        if(response.data.success){
            return response.data;
        }
        throw new Error(response.data.error);
    }catch(e){
        throw new Error(e);
    }
};