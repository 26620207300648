import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  margin: 0 2%;
`;



export const Content = styled.div`
  margin: auto;
  margin-top: ${(props) => props.$overRideMargin ? `${props.$overRideMargin}`: '100px'};
  @media screen and (max-width: 800px) {
    margin-top: ${(props) => props.$overRideMarginMobile ? `${props.$overRideMarginMobile}`: '100px'};
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
  margin-bottom:10px;
  width: 90%;
`;