import { useQuery, useMutation } from "@tanstack/react-query";
import { checkout, purchaseStatus } from "apis/checkout";
import useCartStore from "stores/cartStore";
import useUserStore from "stores/userStore";


export const useCheckout = () => {
    const cart = useCartStore((state)=> state.cart);
    const cart_note = useCartStore((state)=> state.cart_note);
    const send_with_dispatch = useCartStore((state)=> state.send_with_dispatch);
    const couponsData = useCartStore((state)=> state.coupons);
    const lang = useUserStore((state)=> state.lang);
    const coupons = [];
    if(couponsData?.shipping?.code){
        coupons.push(couponsData.shipping.code)
    }
    if(couponsData?.cart?.coupon?.code){
        coupons.push(couponsData.cart.coupon.code)
    }
    const shipping = useCartStore((state)=> state.shipping)
    const { data, error, isError, isPending, mutateAsync } = useMutation({
        mutationKey: ["checkout"],
        mutationFn: ({paymentType}) => checkout(cart, coupons, shipping?.value, paymentType, lang, cart_note, send_with_dispatch),
    });
    return { isPending, error, isError, checkout: mutateAsync}
};

export const usePurchaseStatus = () => {
    const { data, isLoading, isError, failureCount } =  useQuery({
        queryKey: ["purchaseStatus"],
        queryFn: () => purchaseStatus(),
        retry: 3,
        retryDelay: 400,
    });
    return { order: isError || isLoading? {} : data, isLoading, isError, failureCount}
}


