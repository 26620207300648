import React, { useRef, useState } from 'react'
import Delivery from './Delivery'
import Payment from './Payment'
import { CheckoutTab1, CheckoutTab2, CheckoutTab3, TabContainer } from 'components/Common/Tabs';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import { useShipping } from 'hooks/reactQuery/useShippingQuery';

const ShippingForm = ({
    clientSecret,
    setClientSecret,
    total,
    currency,
}) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('delivery');
    const { isError: ShippingIsError, isLoading: ShippingIsLoading} = useShipping();
    const loginActive = ShippingIsError || ShippingIsLoading;
    const deliveryActive = !loginActive && activeTab=="delivery";
    const paymentActive = !loginActive && activeTab=="payment";
    const handleChangeTab = (tab) => {
        setActiveTab(tab);
        scrollToMenu();
    }
    const menuRef = useRef(null);
    const scrollToMenu = () => {
        menuRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    return (
        <div className="shipping-form-wrapper" ref={menuRef}>
            <TabContainer>
                <CheckoutTab1 $isClicked={loginActive} $borderLeft>{t('sign-in')}</CheckoutTab1>
                <CheckoutTab2 $isClicked={deliveryActive} >{t('delivery')}</CheckoutTab2>
                <CheckoutTab3 $isClicked={paymentActive} $borderRight>{t('payment')}</CheckoutTab3>
            </TabContainer>
            <div className="shipping-form">
                {
                    loginActive ?
                    <LoginForm scrollToMenu={scrollToMenu}/> :
                    null
                }
                {
                    deliveryActive ?
                    <Delivery
                        handleChangeTab={handleChangeTab}
                    /> :
                    null
                }
                {
                    paymentActive ?
                    <Payment
                        clientSecret={clientSecret}
                        setClientSecret={setClientSecret}
                        total={total}
                        currency={currency}
                    /> :
                    null
                }
            </div>
        </div>
    )
}

export default ShippingForm