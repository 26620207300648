import { create } from 'zustand'
import { persist } from "zustand/middleware"

const defaultState = {
  cart: [],
  totalQuantity: 0,
  shipping: {},
  currency: 'USD',
  coupons: {
    cart: null,
    shipping: null,
  },
  cart_note: "",
  lastUpdated: null,
  send_with_dispatch: false
}

const isDataExpired = (timestamp) => {
  const now = Date.now();
  return now - timestamp > 2 * 24 * 60 * 60 * 1000;
};


const useCartStore = create(persist((set, get) => ({
  ...defaultState,
  updateCartNote: (note) => set({ cart_note: note }),
  updateDispatch: (send_with_dispatch) => set({ send_with_dispatch: send_with_dispatch }),
  addToCart: (item, quantityOfItem = 1) => set((state) => {
    let productIndex = state.cart.findIndex((product)=> product.id == item.id)
    let newTotalQuantity = state.totalQuantity + quantityOfItem;
    if(productIndex >=0 ){
        const updatedCartItems = [...state.cart];
        if(updatedCartItems[productIndex].price_id === item.price_id){
          updatedCartItems[productIndex].quantity += quantityOfItem;
          return { cart: updatedCartItems, totalQuantity: newTotalQuantity, lastUpdated: new Date().getTime() };
        }else{
          return { cart: [{ ...item, quantity: quantityOfItem }], totalQuantity: quantityOfItem, currency: item.currency, lastUpdated: new Date().getTime() };
        }
    }else{
      if(item.currency !== state.currency){
        return { cart: [{ ...item, quantity: quantityOfItem }], totalQuantity: quantityOfItem, currency: item.currency, lastUpdated: new Date().getTime() };
      }
      return { cart: [...state.cart, { ...item, quantity: quantityOfItem }], totalQuantity: newTotalQuantity, currency: item.currency, lastUpdated: new Date().getTime() };
    }
  }),
  removeItem:(item) => set((state) => {
    const newCart = state.cart.filter((product) => product.id !== item.id);
    const newTotalQuantity = newCart.reduce(
      (total, item) => total + item.quantity,
      0
    );
    return { cart: newCart, totalQuantity: newTotalQuantity, lastUpdated: new Date().getTime() };
  }),
  clearCoupon: (type) => set((state) => {
    return { coupons: { ...state.coupons, [type]: null }, lastUpdated: new Date().getTime() };
  }),
  clearCart: () => set(defaultState),
  updateCart: (newCart) => set((state) => {
    const newTotalQuantity = newCart.reduce(
      (total, item) => total + item.quantity,
      0
    )
    return { cart: [...newCart], totalQuantity: newTotalQuantity, coupons: { cart: null, shipping: null }, lastUpdated: new Date().getTime() };
   }),
   updateShipping: (shipping) => set({ shipping }),
    applyCoupon: (coupon, type) => set((state) => {
      if(type === "shipping"){
        return { coupons: { ...state.coupons, [type]: coupon }, lastUpdated: new Date().getTime() };
      }else{
        return { coupons: { ...state.coupons, cart: {coupon: coupon, type: type} }, lastUpdated: new Date().getTime() };
      }
    }),
  getTotalQuantity: () => {
    return get().cart.reduce(
      (total, item) => total + item.quantity,
      0
    );},
    getTotalPrice: () => {
    return get().cart.reduce((totalPrice, item) => {
        return totalPrice + (item.quantity * item.price);
    }, 0);
    },
    initializeStore: () => {
      const { lastUpdated, clearCart } = get();
      if (lastUpdated && isDataExpired(lastUpdated)) {
        clearCart()
      }
    },
 }),
 {
    name: "cart",
    
}
));
useCartStore.getState().initializeStore();

export default useCartStore;
