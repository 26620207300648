import styled from 'styled-components'

const StyledTrustpilotLogo = styled.img`
    width: ${(props) => props.width || 'initial'};
`;

export const TrustpilotLogo = ({ src, alt, width, ...props }) => {
  return <StyledTrustpilotLogo src={src} alt={alt} width={width} {...props}/>;
};

const StyledImageLogo = styled.img`
    width: ${(props) => props.width || 'initial'};
`;

export const ImageLogo = ({ src, alt, width, ...props }) => {
  return <StyledImageLogo src={src} alt={alt} width={width} {...props} />;
};

const StyledSVGLogo = styled.img`
    width: ${(props) => props.width || 'initial'};
`;

export const SVGLogo = ({ src, alt, width }) => {
  return <StyledSVGLogo src={require('../../assets/images/social-media/insta.svg').default} alt={alt} width={width}/>;
};