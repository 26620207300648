import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import useCartStore from 'stores/cartStore';

function PaymentSuccessful({order}) {
  const {t} = useTranslation();
  const clearCart = useCartStore(state => state.clearCart);
  useEffect(()=>{
    clearCart()
  },[])
  const countries = [
    { name: t('United Arab Emirates'), key: 'UAE' },
    { name: t('Saudi Arabia'), key: 'Saudi_Arabia' },
    { name: t('Qatar'), key: 'Qatar' },
    { name: t('Kuwait'), key: 'Kuwait' },
    { name: t('Bahrain'), key: 'Bahrain' },
    { name: t('Oman'), key: 'Oman' },
    { name: t('Lebanon'), key: 'Lebanon' },
    { name: t('US, UK, and Europe'), key: 'US_UK_Europe' }
  ];
  return (
    <div className="container">
        <h1 >{t('thankyoutitle2')} {order.first_name}!</h1>
        <h3 >{t('orderreceived')}</h3>
        <h2>{t('shippingInfoTitle')}</h2>
        <h3>{t('shippingInfoDescription')}</h3>
        {countries.map(country => (
          <li key={country.key}>
            {country.name}: {t(`shipping_times.${country.key}`)}
          </li>
        ))}
        <br/>
        <h3>{t('shippingHelp')}</h3>
        <h3><b>{t('shippingHelp2')}</b></h3>
    </div>
  )
}

export default PaymentSuccessful
