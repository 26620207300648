import Axios from 'axios';
import Cookies from 'js-cookie';
// import Cookies from 'js-cookie';
const axios = Axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

axios.interceptors.request.use(config => {
    const country = Cookies.get('country'); 
    if (config.url) {
      const url = new URL(config.url, config.baseURL);
      url.searchParams.append('country', country);
      config.url = url.toString().replace(config.baseURL, '');
    }
    return config;
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
})
axios.defaults.headers.post['Accept'] = 'application/json'

export default axios;