import styled from 'styled-components'


export const ProductImage = styled.img`
  width: 100%;
  border-radius: 5px;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative;
  transition: all .3s ease;
`;
export const ProductImageHover = styled.img`
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all .3s ease;
`;

export const ProductActionsContainer = styled.div`
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  gap: 10px;
`;
export const ProductActionLink = styled.a`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  color: #333;
  opacity: 0;
  transition: all .3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({$marginHorizontal}) => $marginHorizontal ? `0px ${$marginHorizontal}` : '0px'};
  &:hover {
    background-color: #ff2d47;
  }
  &:hover svg, &:hover svg path{
    color: #fff;
  }
`;

export const ProductCardWrapper = styled.div`
  text-align: center;
  width: calc(20% - 10px);
  @media screen and (max-width: 1024px)  {
    width: calc(25% - 10px);
  }
  @media screen and (max-width: 767px){
    width: calc(33% - 10px);
  }
  @media screen and (max-width: 500px){
    width: calc(50% - 10px);
  }
  @media screen and (max-width: 250px){
    width: calc(80% - 10px);
  }
  &:hover ${ProductImageHover}{
    opacity:1;
  }
`;
export const ProductImageContainer = styled.div`
  position: relative;
  &:hover ${ProductActionLink}{
    opacity:1;
    transform: translateY(-30%);
    cursor: pointer;
  }
`



export const ProductName = styled.a`
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  color: #7a7a7a;
  cursor: pointer;
  &:hover {
    color: #ff2d47;
  }
  @media screen and (min-width: 1700px)  {
    font-size: 16px;
  }
`;

export const ProductPrice = styled.p`
  margin: 0px;
  font-size: 16px;
  font-family: ${props => props.theme.fontFamily.en_semiBold};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
  @media screen and (min-width: 1700px)  {
    font-size: 18px;
  }
`;
export const OldProductPrice = styled.s`
  font-size: 14px;
  margin: 0;
  margin-left: 10px;
  color: #ddd;
  display: inline-block;
  @media screen and (min-width: 1700px)  {
    font-size: 16px;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  gap: 3px;
  
`;

export const StarIcon = styled.span`
  width: 15px;
  @media screen and (min-width: 1700px)  {
    width: 18px;
  }
`;
export const PaymentTypeContainer = styled.div` 
    position: absolute;
    top: 5%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
