import React from 'react'
import { useTranslation } from 'react-i18next';

function PaymentFailed({order}) {
  const {t} = useTranslation();
  return (
    <div className="container">
       <h1>{t('errorPageTitle')}</h1>
        <p>
        <strong>{t('errorPageListTitle')}</strong>
        <ul>
            <li>{t('errorPagePointOne')}</li>
            <li>{t('errorPagePointTwo')}</li>
            <li>{t('errorPagePointThree')}</li>
            <li>{t('errorPagePointFour')}</li>
            <li>{t('errorPagePointFive')}</li>
        </ul>
        </p>
    </div>
  )
}

export default PaymentFailed
