import React from 'react'
import { useTranslation } from 'react-i18next';

function PaymentException({order}) {
  const {t} = useTranslation();
  return (
    <div className="container">
        <h1>
        {t("thankyoutitle")} {order.first_name}!
        </h1>
        <h2>{t("Exceptiontext1")}</h2>
        <h2>{t("Exceptiontext2")}</h2>
        <br/>
        <h2>{t("thankyoutitle")}</h2>
    </div>
  )
}

export default PaymentException
