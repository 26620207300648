import React from 'react'
import { CartCount, CartIcon, CartIconContainer, Language, Logo, MainMenu, MenuContainer, SubActions } from './style'
import { Links } from 'components/Common/Links'
import { ImageLogo } from 'components/Common/Logos'
import useCartStore from 'stores/cartStore';
import { useNavigate } from 'react-router-dom';
import useUserStore from 'stores/userStore';
import basmaLogo from "assets/images/general/logo.svg";
import cartIcon from "assets/images/general/cart.png"

function Menu() {
    const { lang, setLang } = useUserStore();
    const  totalQuantity = useCartStore((state)=> state.totalQuantity);
    const navigate = useNavigate();
   
  return (
    <MenuContainer>
        {/* <SubActions $justifyContent='start'>
            <Language onClick={() => setLang(lang == 'ar' ? 'en' : 'ar')} $lang={lang}>{lang == 'ar' ? 'English' : 'عربي'}</Language>
        </SubActions> */}
        <MainMenu>
            <Logo onClick={()=>{navigate("/")}}><ImageLogo alt="store.basma.com" src={basmaLogo} width="118px;"/></Logo>
        </MainMenu>
        <SubActions>
        <Language onClick={() => setLang(lang == 'ar' ? 'en' : 'ar')} $lang={lang}>{lang == 'ar' ? 'English' : 'عربي'}</Language>
            <CartIconContainer>
            <CartIcon>
                <Links href='/cart'><ImageLogo alt='BASMA logo' src={cartIcon} /></Links>
            </CartIcon>
            <CartCount>{totalQuantity}</CartCount>
            </CartIconContainer>
        </SubActions>
      </MenuContainer>
  )
}

export default Menu