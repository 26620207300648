import React from 'react'
import PaymentException from 'components/PaymentStatuses/PaymentException';
import PaymentFailed from 'components/PaymentStatuses/PaymentFailed';
import PaymentPending from 'components/PaymentStatuses/PaymentPending';
import PaymentSuccessful from 'components/PaymentStatuses/PaymentSuccessful';
import { usePurchaseStatus } from 'hooks/reactQuery/useCheckoutQuery';
import { Container, Content } from 'components/Common/Wrapper';
import Footer from 'components/GeneralLayout/Footer';
import Menu from 'components/GeneralLayout/Menu';
import styled from 'styled-components';

function PurchaseStatus() {
  const {isLoading, isError, failureCount, order} = usePurchaseStatus();
  let component = null;
  if(order?.status){
    if(order.status === 'Paid with exception'){
      component =  <PaymentException order={order}/>
    }else if(order.status === 'Failed' || order.status === 'Cancelled'){
      component =  <PaymentFailed order={order}/>
    }else if(order.status === 'Paid'){
      component =  <PaymentSuccessful order={order}/>
    }
  }else if(failureCount ==4 ){
    component =  <PaymentPending />
  }
    
const PageContainer = styled.div`
  min-height: 40vh;
`;

  return (
    <Container>
        <Menu/>
        <Content>
            <PageContainer>
                {component}
            </PageContainer>
        </Content>
        <Footer/>
    </Container>
  )
}

export default PurchaseStatus
