import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import Star from "assets/images/general/Star.svg"
import { ImageLogo } from 'components/Common/Logos';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import { AddToCartButton } from 'components/Common/Buttons';
import useCartStore from 'stores/cartStore';
import { useProduct } from 'hooks/reactQuery/UseProductsQuery';
import { ProductIcon, SaleInfo } from '../helper';
import ReactHtmlParser from 'html-react-parser';
import "./index.css"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//all 0.5s ease 0s

// Styled components


const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  flex-grow: 1;
  width:80%;
  margin:auto;
  @media screen and (max-width: 300px){
    justify-content: center;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  border-radius: 5px;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative;
  transition: all .3s ease;
`;

const ProductImage2 = styled.img`
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all .3s ease;
`;

const ProductActionLink = styled.a`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  color: #333;
  opacity: 0;
  transition: all .3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ff2d47;
  }
  &:hover svg{
    color: #fff;
  }
`;
const ProductCard = styled.div`
  text-align: center;
  margin: 10px;
  width: calc(20% - 20px);
  @media screen and (max-width: 1024px)  {
    width: calc(25% - 20px);
  }
  @media screen and (max-width: 767px){
    width: calc(33% - 20px);
  }
  @media screen and (max-width: 500px){
    width: calc(50% - 20px);
  }
  @media screen and (max-width: 250px){
    width: calc(80% - 20px);
  }
  &:hover ${ProductImage2}{
    opacity:1;
  }
`;
const ProductImageContainer = styled.div`
  position: relative;
  &:hover ${ProductActionLink}{
    opacity:1;
    transform: translateY(-30%);
  }
`



const ProductName = styled.h3`
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #ff2d47;
  }
  margin-top: 0px;
  margin-bottom:10px;
`;


const RatingContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  gap: 5px;
`;

const StarIcon = styled.span`
  width: 15px;
`;
const AddToCartWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`
const AddQuantityWrapper = styled.div`
    font-size: 25px;
    width: 140px;
    height: 40px;
    text-align: center;
    position: relative;
    font-family: ${props => props.theme.fontFamily.en_semiBold};
    .ar & {
      font-family: ${props => props.theme.fontFamily.ar_bold};
    }
`
const AddQuantityButton = styled.button`
  background-color: unset;
  border: none;
  ${props => props.$right ? 'right: 0;' : 'left: 0;'}
  top: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
`
const ProductDetailsWrapper = styled.div`
    display: flex; 
    gap: 2%;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      gap: 10px;
    }

`
const ImagesWrapper = styled.div`
    display: flex;
    flex: 1 0 48%;
    flex-direction: column;
    gap: 10px;
    max-width: 48%;
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
    @media screen and (min-width: 1300px) {
      flex-direction: ${props => props.$inModal ? 'column;': 'row-reverse;'}
    }
 
`
const SliderWrapper = styled.div`
    width: 100%;
    position: relative;
    @media screen and (min-width: 1300px) {
      width: ${props => props.$inModal ? '100%;': '80%;'}
    }
`
const ImageOptionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow: auto;
    @media screen and (min-width: 1300px) {
      width: ${props => props.$inModal ? 'initial;': '20%;'}
      flex-direction: ${props => props.$inModal ? 'row;': 'column;'};
      overflow: ${props => props.$inModal ? 'auto;': 'unset;'};;
    }
`

const ImageOption = styled.img`
    height: 120px;
    @media screen and (min-width: 1300px) {
      width: ${props => props.$inModal ? 'unset': '100%;'};
      height: ${props => props.$inModal ? '100px': 'unset;'} ;
    }
    @media screen and (max-width: 500px) {
      height: 100px;
    }
    @media screen and (max-width: 400px) {
      height: 80px;
    }
`
// add these if props.$inModal is true
const ProductDescription = styled.div`
  ${props => props.$inModal ? 
    'overflow: hidden; text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;'
    : ''
  }
`

function ProductDetails({ inModal, productUrl}) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [quantityToAdd, setQuantityToAdd] = useState(1)
  const addToCart = useCartStore((state)=> state.addToCart);
  const { t, i18n } = useTranslation()
  const { product , isLoading, isError} = useProduct(productUrl);
  const navigate = useNavigate();
  const titleClicked = () => {
    if(inModal){
      navigate(`/products/${productUrl}`)
    }
  }
  useEffect(() => {
    if(isError){
      navigate("/")
    }
  }, [isError])
  const sliderRef = useRef(null);
  const settings = {
      lazyLoad: true,
      dots: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) => setSelectedIndex(next),
      initialSlide: i18n.language =="ar" ?product?.images?.length - 1 : 0,
      rtl: i18n.language =="ar",
  };  
  const goToSlide = (index) => {
      sliderRef.current.slickGoTo(index);
  };
  if(isLoading){
    return 
  }
  if(!isError)

  return (
    <ProductDetailsWrapper>
          <ImagesWrapper $inModal={inModal}>
            <SliderWrapper $inModal={inModal}>
              <Slider ref={sliderRef} {...settings}>
                {product.images.map((image, key)=>
                    <div key={key}>
                      <img src={image.file_url} style={{width:"100%"}} />
                  </div>
                  )}
              </Slider>
              <ProductIcon product={product}/>
            </SliderWrapper>
             <ImageOptionsWrapper $inModal={inModal}>
              {product.images.map((image, key)=>
                <div key={key}>
                  <ImageOption $inModal={inModal} src={image.file_url} style={{opacity: selectedIndex==key?1:0.5, border:selectedIndex==key? "1px solid #000":"1px solid #ddd"}} onClick={()=>{goToSlide(key)}}/>
              </div>
              )}
            </ImageOptionsWrapper>
          </ImagesWrapper>
          <div style={{flex:"1 0 48%"}}>
          <ProductName onClick={titleClicked}>{product.title[i18n.language]}</ProductName>
            <RatingContainer>
              {[...Array(5)].map((_, index) => (
                <StarIcon key={index}>
                  <ImageLogo alt="Star" src={Star} />
                </StarIcon>
                ))}
            </RatingContainer>
            <SaleInfo product={product}/>
            <ProductDescription $inModal={inModal}>{ReactHtmlParser(product.description[i18n.language])}</ProductDescription>
          <AddToCartWrapper>
            <AddQuantityWrapper>
            <AddQuantityButton disabled={quantityToAdd == 1} onClick={()=>{setQuantityToAdd((prev)=>prev-1)}}><IoMdArrowDropdown fontSize='x-large'/></AddQuantityButton>
            <span>{quantityToAdd}</span>
            <AddQuantityButton $right onClick={()=>{setQuantityToAdd((prev)=>prev+1)}}><IoMdArrowDropup fontSize='x-large'/></AddQuantityButton>
            </AddQuantityWrapper>
            <AddToCartButton onClick={()=>{addToCart(product, quantityToAdd)}}>{t('add-to-cart')}</AddToCartButton>
          </AddToCartWrapper>
          </div>
        </ProductDetailsWrapper>
  )
}

export default ProductDetails