import { PayButton, Button } from 'components/Common/Buttons';
import { LinkWithHover } from 'components/Common/Links';
import React from 'react'
import { useTranslation,Trans  } from 'react-i18next';
import useCartStore from 'stores/cartStore';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 600px;
  max-width: 80%;
`;

const Title = styled.h2`
  margin: 0 0 10px 0;
`;

const Body = styled.p`
  margin: 0 0 20px 0;
  white-space: pre-line;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => (props.primary ? props.theme.colors.red_salsa: '#6c757d')};
  color: white;
  &:hover {
    opacity: 0.9;
    background-color: ${props => (props.primary ? props.theme.colors.light_red_salsa: '#6c757d')};
  }
`;

function SendWithDispatch({dispatch, turnOffDispatch}) {

    const updateDispatch = useCartStore((state)=> state.updateDispatch)
    const updateDispatchTable = (shouldCombine) => {
      updateDispatch(shouldCombine)
      turnOffDispatch()
    }
    const { t } = useTranslation();
    return (
        <Overlay>
          <PopupContainer>
            <Title>
            <Trans i18nKey="shipment-title" /></Title>
            <Body><Trans i18nKey="shipment-body" values={{date: dispatch?.target_dispatch_date}} components={{ b: <b /> }} /></Body>
            <PayButton onClick={()=>{updateDispatchTable(true)}} $padding={"10px"} $borderRadius={'5px'} $margin={'unset'} style={{marginBottom:"10px"}} >{t('same-shipments')}</PayButton>
            <LinkWithHover $fontSize={"14px"} onClick={()=>{updateDispatchTable(false)}}>{t('separate-shipments')}</LinkWithHover>
          </PopupContainer>
        </Overlay>
      );
}

export default SendWithDispatch
