import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Footer from '../components/GeneralLayout/Footer';
import Menu from 'components/GeneralLayout/Menu';
import TrustBox from 'components/GeneralLayout/TrustBox';
import { Container, Content } from 'components/Common/Wrapper';
import ProductDetails from 'components/Products/ProductDetails';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const SingleProductPage = () => {
  const {url} = useParams();
  return (
    <Container>
      <Menu/>
      <Content $overRideMargin={"200px"}>
        <ProductDetails productUrl={url}/>
        <TrustBox/>
      </Content>
      <Footer/>
    </Container>
  );
};

export default withTranslation()(SingleProductPage);
