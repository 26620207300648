import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { PayButton } from 'components/Common/Buttons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const StripeCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }
    setIsLoading(true);

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_REDIRECT+"purchase-status",
      },
    });

    
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setIsLoading(false);
  };

  return (
    <div >
      <PaymentElement id="payment-element"/>
      <PayButton $padding={"15px"} $width={"100%"} $borderRadius={'5px'} $margin={'2em auto auto auto'}
       disabled={isLoading || !stripe || !elements} onClick={handleSubmit}>
        {t('pay-now')}
      </PayButton>
      {message ? <p className='checkout-coupon-error'>{message}</p>: null}
    </div>
  )
};

export default StripeCheckoutForm;
