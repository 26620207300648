import React, { useState } from 'react'
import ShippingForm from './ShippingForm2'
import CartBreakdown from './CartBreakdown'
import { Content } from 'components/Common/Wrapper'

const CheckoutForms = ({
    clientSecret,
    setClientSecret,
    total,
    totalPrice,
    totalDiscount,
    shippingPrice,
    currency,
}) => {
  return (
    <Content>
        <div className="checkout-page">
            <ShippingForm
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                total={total}
                currency={currency}
            />
            <CartBreakdown
                clientSecret={clientSecret}
                totalPrice={totalPrice}
                totalDiscount={totalDiscount}
                total={total}
                shippingPrice={shippingPrice}
                currency={currency}
            />
        </div>
    </Content>
  )
}

export default CheckoutForms