import styled from 'styled-components'

export const Links = styled.a`
  opacity: 1;
  color: ${props => props.theme.colors.dim_gray};
  font-family: ${props => props.theme.fontFamily.en_semiBold};
  font-size: 14px;
  line-height: 30px;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
}`

export const LinkWithHover = styled.a`
  font-size: ${props => props.$fontSize || "12px"};
  text-transform: ${props => props.$textTransform || "none"};
  text-decoration: none;
  color: ${props => props.$color || props.theme.colors.sonic_silver};
  cursor: pointer;
  pointer-events : ${props => props.$disabled ? 'none' : 'all'};
  &:hover {
    color: ${props => props.theme.colors.red_salsa};
  }
  font-family: ${props => props.theme.fontFamily.en_bold03};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
`;