import React from 'react'
import { useTranslation } from 'react-i18next';

function PaymentPending() {
  const {t} = useTranslation();
  return (
    <div className="container">
        <h2>{t("Pendingtext1")}</h2>
        <h2>{t("Pendingtext2")}</h2>
    </div>
  )
}

export default PaymentPending
