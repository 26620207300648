import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import Cookies from 'js-cookie';
import i18n from 'utils/i18n';

const useUserStore = create(
  persist((set, get) => ({
    lang: Cookies.get('language') || 'en',
    country:  Cookies.get('country') || 'LB',
    guest: false,
    setLang: (newLang) => {
      set({ lang: newLang })
      i18n.changeLanguage(newLang)
      Cookies.set('language', newLang)
    },
    setGuest: (isGuest) => {
      set({ guest: isGuest })
    }

  }),
  {
    name: 'user',
  },
  )
)
export default useUserStore;