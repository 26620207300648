import styled from 'styled-components'

export const MenuContainer = styled.nav`
  background-color: ${props => props.theme.colors.white};
  padding: 10px 5%;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 80px;
`;

export const Language = styled.span`
  cursor: pointer;
  font-family: ${props => props.$lang == 'en' ? props.theme.fontFamily.ar_regular :  props.theme.fontFamily.en_regular};
`;

export const Logo = styled.h1`
  margin: 0;
  cursor: pointer;
  text-align: center;
  flex: 1;
`;

export const CartIcon = styled.span`
  font-size: 24px;
  cursor: pointer;
`;

export const MainMenu = styled.div`
    width: 50%;
`
export const SubActions = styled.div`
    width: 25%;
    display: flex;
    justify-content: ${props => props.$justifyContent || 'end'};
    gap: 10%;
`
export const CartIconContainer = styled.div`
    position: relative;
  `;
  
export const CartCount = styled.div`
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: #ff2d47;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.fontFamily.en_semiBold};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
`;  