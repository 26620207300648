import axios from "utils/axios";


export const login = async (body) => {
    try{
        const response = await axios.post(`auth/login`, body);
        if(response.status == 200){
            return response.data;
        }
        throw new Error(response.data.error);
    }catch(e){
        throw new Error(e);
    }
};

export const guestLogin = async (body) => {
    try{
        const response = await axios.post(`auth/guest_login`, body);
        if(response.status == 200){
            return response.data;
        }
        throw new Error(response.data.error);
    }catch(e){
        throw new Error(e);
    }
};