import React, { useEffect, useState } from 'react'
import { ContactSection, FooterMainDiv, LinkSection, LinkSectionTitle, LogoSection, MainContent, PaymentMethods, Privacy, SectionWithTitle, SubContent } from './styles'
import { ImageLogo, TrustpilotLogo } from 'components/Common/Logos'
import { Links } from 'components/Common/Links'
import ADTA from "assets/images/general/ADTA.svg"
import Lebanon from "assets/images/general/lebanon.svg"
import UAE from "assets/images/general/UAE.png"
import KSA from "assets/images/general/KSA.png"
import QAR from "assets/images/general/QAR.png"
import KUW from "assets/images/general/KUW.png"
import OMAN from "assets/images/general/oman.png"
import BH from "assets/images/general/bahrain.png"
import UK from "assets/images/general/UK.png"
import Master from "assets/images/general/master.svg"
import Visa from "assets/images/general/visa.svg"
import { useTranslation } from 'react-i18next'
import useUserStore from 'stores/userStore'
import parse from 'html-react-parser';
import Cookies from 'js-cookie'

function Footer() {
  const { t } = useTranslation();
  const [countryDetails, setCountryDetails] = useState({
    phone: '+971 4 278 9914',
    flag: UAE,
    alt: 'ae-flag-alt',
    code: 'AE',
  });
  const country = Cookies.get('country');
  const lang = useUserStore((state) => state.lang);
  const countries = [
    {flag: Lebanon, alt: t('lb-flag-alt'), code: 'LB', phone: '81 144 653'},
    {flag: UAE, alt: t('ae-flag-alt'), code: 'AE', phone: '+971 4 278 9914'},
    {flag: KSA, alt: t('sa-flag-alt'), code: 'SA', phone: '+966 11 834 3423'},
    {flag: QAR, alt: t('qa-flag-alt'), code: 'QA', phone: '+971 4 278 9914'},
    {flag: KUW, alt: t('kw-flag-alt'), code: 'KW', phone: '+965 2205 2637'},
    {flag: OMAN, alt: t('om-flag-alt'), code: 'OM', phone: '+968 24 442694'},
    {flag: BH, alt: t('bh-flag-alt'), code: 'BH', phone: '+973 1663 9174'},
    {flag: UK, alt: t('gb-flag-alt'), code: 'GB', phone: '+971 4 278 9914'},
  ];

  useEffect(() => {
    let countryInfo = countries.find((c) => c.code === country);
    setCountryDetails({
      phone: countryInfo?.phone,
      flag: countryInfo?.flag,
      alt: countryInfo?.alt,
      code: countryInfo?.code,
    });
  }, [])

  return (
    <FooterMainDiv>
      <MainContent>
        <LogoSection>
          <ImageLogo
            alt='store.basma.com'
            src='https://shop.basma.com/cdn/shop/files/Basma_logo_bigger_file.png?v=1614756317'
            width={'20%'}
          />
          <TrustpilotLogo
            src={require('assets/images/general/trustpilot-en.png')}
            alt='Trustpilot Logo'
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ImageLogo alt='BASMA logo' src={ADTA} width={'70%'} />
            <ImageLogo
              alt='BASMA logo'
              src={require('assets/images/general/iso.png')}
              width={'20%'}
            />
          </div>
        </LogoSection>
        <ContactSection>
          <SectionWithTitle>
            <LinkSectionTitle>{t('learn-more')}</LinkSectionTitle>
            <LinkSection>
              <Links href={`https://basma.com/${lang}/about`}>{t('about-basma')}</Links>
              <Links href={`https://basma.com/${lang}/guarantees`}>{t('our-guarantees')}</Links>
              <Links href={`https://basma.com/${lang}/doctors`}>{t('doctors')}</Links>
              <Links href={`https://basma.com/${lang}/faqs`}>{t('faqs')}</Links>
              <Links href='https://blog.basma.com'>{t('blog')}</Links>
            </LinkSection>
          </SectionWithTitle>
          <SectionWithTitle>
            <LinkSectionTitle>{t('locations')}</LinkSectionTitle>
            <LinkSection>
              <Links href={`https://basma.com/${lang}/booking-listing-1?clinics=all`}>{t('basma-clinics')}</Links>
            </LinkSection>
          </SectionWithTitle>
          <SectionWithTitle>
            <LinkSectionTitle>{t('contact-us')}</LinkSectionTitle>
            <LinkSection>
              <Links href={`tel:${countryDetails.phone}`}>{parse(t('call-us', {phoneNb: countryDetails.phone}))}</Links>
              <Links href='https://api.whatsapp.com/send?phone=971545755078' target='_blank'>{t('chat')}</Links>
            </LinkSection>
          </SectionWithTitle>
          <SectionWithTitle>
            <LinkSectionTitle>{t('grin')}</LinkSectionTitle>
            <LinkSection>
              <Links href={`https://basma.com/${lang}/refer-a-friend`}>{t('invite-friend')}</Links>
              <Links href='https://www.instagram.com/Wearebasma/' target='_blank'>{t('we-are-basma')}</Links>
            </LinkSection>
          </SectionWithTitle>
        </ContactSection>
      </MainContent>
      <SubContent>
        <PaymentMethods>
          <ImageLogo alt={countryDetails.alt} src={countryDetails.flag} width={'25px'} />
          <LinkSectionTitle>{countryDetails.code}</LinkSectionTitle>
          <ImageLogo alt='Visa' src={Visa} width={'50px'} />
          <ImageLogo alt='Master' src={Master} width={'30px'} />
        </PaymentMethods>
        <Privacy>
          <Links href={`https://basma.com/${lang}/privacy-policy`}>{t('privacy')}</Links>
          <Links href={`https://basma.com/${lang}/terms-and-conditions`}>{t('terms')}</Links>
          <Links href={`https://basma.com/${lang}/refund-policy`}>{t('returns')}</Links>
          <Links href='https://www.instagram.com/Wearebasma/' target='_blank'><ImageLogo alt='Insta' src={require("assets/images/social-media/insta.svg").default} width={'30px'} /></Links>
          <Links href={`https://www.tiktok.com/@wearebasma?lang=${lang}`} target='_blank'><ImageLogo alt='Tiktok' src={require("assets/images/social-media/tiktok.svg").default} width={'30px'} /></Links>
          <Links href='https://www.youtube.com/channel/UCQR3x5E2Dxzfw9YV0kVY5bA' target='_blank'><ImageLogo alt='Youtube' src={require("assets/images/social-media/YouTubeIcon.svg").default} width={'30px'} /></Links>
          <Links href='https://twitter.com/wearebasma' target='_blank'><ImageLogo alt='X' src={require("assets/images/social-media/X.svg").default} width={'30px'} /></Links>
          <Links href='https://www.facebook.com/wearebasma' target='_blank'><ImageLogo alt='Facebook' src={require("assets/images/social-media/FBI.svg").default} width={'30px'} /></Links>
        </Privacy>
      </SubContent>
    </FooterMainDiv>
  )
}

export default Footer
