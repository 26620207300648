import PopupModal from 'components/GeneralLayout/PopupModal'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist';
import { IoCloseCircleOutline } from "react-icons/io5";
import Cookies from 'js-cookie';
import { useShipping } from 'hooks/reactQuery/useShippingQuery';
import { useGuestLogin, useUserLogin } from 'hooks/reactQuery/useUserQuery';
import { PayButton } from 'components/Common/Buttons';
import { useTranslation } from 'react-i18next';
import useUserStore from 'stores/userStore';
import useCartStore from 'stores/cartStore';
import { LinkWithHover } from 'components/Common/Links';

const LoginForm = ({ scrollToMenu }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loginFormRef = useRef();
    const { isError: ShippingIsError, refetch: refetchShipping } = useShipping();
    const { isError: userLoginIsError, login, isPending: userLoginIsPending } = useUserLogin();
    const { isError: guestLoginIsError, guestLogin, isPending: guestLoginIsPending } = useGuestLogin();
    const [guestSignIn, setGuestSignIn] = useState(false);
    const setGuest = useUserStore((state) => state.setGuest);    

    const switchMode = (event) => {
        event.preventDefault();
        setGuestSignIn(prevState =>!prevState);
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        const formData = new FormData(loginFormRef.current);
        const email = formData.get('email');
        const password = formData.get('password');
        let body = { email, password }
        login(body, {
            onError: () => {
                loginFormRef.current.reset();
            },
            onSuccess: async (data) => {
                setGuest(false)
                // let sharedCookie = {};
                let sharedCookie = { domain: process.env.REACT_APP_BASE_DOMAIN, path: '/', secure: true, sameSite: 'lax' };
                Cookies.set('access_token', data?.access_token, sharedCookie);
                Cookies.set('user-name', data?.user?.full_name, sharedCookie);
                Cookies.set('user-email', data?.user?.email, sharedCookie);
                Cookies.set('user-phone', data?.user?.phone, sharedCookie);
                let shippingData = await refetchShipping();
                scrollToMenu()
            }
        })
    }
    const handleGuestLogin = async (event) => {
        event.preventDefault();
        const formData = new FormData(loginFormRef.current);
        const email = formData.get('email');
        let body = { email }
        guestLogin(body, {
            onError: () => {
                loginFormRef.current.reset();
            },
            onSuccess: async (data) => {
                if(data.type == "user"){
                    switchMode();
                }else{
                    setGuest(true)
                    // let sharedCookie = { domain: process.env.REACT_APP_BASE_DOMAIN, path: '/', secure: true, sameSite: 'lax' };
                    Cookies.set('access_token', data?.access_token);
                    let shippingData = await refetchShipping();
                    scrollToMenu()
                }
            }
        })
    }


    return (
        ShippingIsError ?
        !guestSignIn?
            <form className='login-form' ref={loginFormRef}>
                <h2>{t('login')}</h2>
                <input
                    type="email"
                    className='checkout-input'
                    name="email"
                    placeholder={t('email-address')}
                />
                <input
                    type="password"
                    className='checkout-input'
                    name="password"
                    placeholder={t('password')}
                />
                {userLoginIsError ? <p className='form-error'>{t('login-error')}</p> : null}
                <PayButton disabled={userLoginIsPending} $padding={"15px"} $width={"100%"} $borderRadius={'5px'} onClick={handleLogin}>{t('sign-in')}</PayButton>
                <LinkWithHover $disabled={userLoginIsPending} $fontSize={"14px"} onClick={switchMode}>{t('guest-sign-in')}</LinkWithHover>
            </form>
         :
         <form className='login-form' ref={loginFormRef}>
                <h2>{t('guest-sign-in')}</h2>
                <input
                    type="email"
                    className='checkout-input'
                    name="email"
                    placeholder={t('email-address')}
                />
                {userLoginIsError ? <p className='form-error'>{t('login-error')}</p> : null}
                <PayButton disabled={userLoginIsPending} $padding={"15px"} $width={"100%"} $borderRadius={'5px'} onClick={handleGuestLogin}>{t('sign-in')}</PayButton>
                <p style={{margin:"0"}}>{t('are-you-a-user')}</p>
                <LinkWithHover $disabled={userLoginIsPending} $fontSize={"14px"} onClick={switchMode} $color='#404040'>{t('user-sign-in')}</LinkWithHover>
            </form>
         :
        null
    )
}

export default LoginForm