import { useQuery, useMutation } from "@tanstack/react-query";
import { guestLogin, login } from "apis/users";
  
  
export const useUserLogin = () => {
    const { data, error, isError, isPending, mutateAsync } = useMutation({
        mutationKey: ["login"],
        mutationFn: (body) => login(body),
    });
    return { userData: data ?? {}, isPending, error, isError, login: mutateAsync}
};

export const useGuestLogin = () => {
    const { data, error, isError, isPending, mutateAsync } = useMutation({
        mutationKey: ["guestLogin"],
        mutationFn: (body) => guestLogin(body),
    });
    return { userData: data ?? {}, isPending, error, isError, guestLogin: mutateAsync}
};