import React from 'react'
import { ModalWrapper, ProductModal } from './styles'

function PopupModal({ handleBackgroundClick, width = null, children}) {
  return (
    <ModalWrapper onClick={handleBackgroundClick}>
      <ProductModal $width={width}>
        {children}
      </ProductModal>
    </ModalWrapper>
  )
}

export default PopupModal