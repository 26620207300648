import styled from 'styled-components'

export const TrustBoxWrapper = styled.div`
    margin: 5% 0%;
    @media screen and (max-width: 500px){
        margin: 5% 3% 10% 3%
    }
    @media screen and (max-width: 350px){
        margin: 5% 0% 10% 0%
    }

`