import { ThemeProvider } from 'styled-components';

const theme = {
    colors: {
      //? for color names search https://www.color-name.com/hex/$color to get the color name
      //? all colors will be lower case underscore seperated : Coral Pink = coral_pink
      black: '#404040',
      white: '#fff',
      tulip: '#ff8a99',
      dim_gray: '#6b6b6b',
      onyx: '#373b3b',
      sonic_silver: '#7a7a7a',
      red_salsa: "#ff2d47",
      light_red_salsa: "#ff2d47bf",
      cultured:"#f8f8f8",
      venetian_red: "#EF364B",
      light_silver: "#D8D8D8",
    },
    fontSizes: {
      small: '12px',
      medium: '16px',
      large: '20px',
    },
    fontFamily:{
        ar_regular: 'Almarai-Regular',
        ar_bold: 'Almarai-Bold',
        ar_extraBold: 'Almarai-ExtraBold',
        en_regular: 'Eina03-Regular',
        en_semiBold: 'Eina03-SemiBold',
        en_bold03: 'Eina03-Bold',
        en_bold04: 'Eina04-Bold',
  }
};
  
const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
  