import styled from 'styled-components'

export const TabContainer = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    background-color: ${props => props.theme.colors.light_silver};
    border-radius: 5px;
`;

export const CheckoutTab1 = styled.span`
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    padding: 0.7rem;
    font-family: ${props => props.theme.fontFamily.en_bold04};
    font-size: 16px;
    text-align: center;
    background-color: ${props => props.$isClicked ? props.theme.colors.red_salsa : props.theme.colors.light_silver};
    clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
    border-radius: ${props => props.$borderLeft ? '5px 0 0 5px' : (props.$borderRight ? '0 5px 5px 0' : '0px')};
    .ar & {
        font-family: ${props => props.theme.fontFamily.ar_extraBold};
        clip-path: polygon(100% 0%, 10% 0, 0% 50%, 10% 100%, 100% 100%);
    }

    @media screen and (max-width: 800px) {
        font-size: 14px;
    }

    @media screen and (max-width: 460px) {
        text-align: left;
        .ar & {
            text-align: right;
        }
    }
`;

export const CheckoutTab2 = styled.span`
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    padding: 0.7rem;
    font-family: ${props => props.theme.fontFamily.en_bold04};
    font-size: 16px;
    text-align: center;
    background-color: ${props => props.$isClicked ? props.theme.colors.red_salsa : props.theme.colors.light_silver};
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    .ar & {
        font-family: ${props => props.theme.fontFamily.ar_extraBold};
        clip-path: polygon(10% 0%, 0% 50%, 10% 100%, 100% 100%, 90% 50%, 100% 0%);
    }

    @media screen and (max-width: 800px) {
        font-size: 14px;
    }

    @media screen and (max-width: 460px) {
        text-align: right;
        .ar & {
            text-align: left;
        }
    }
`;

export const CheckoutTab3 = styled.span`
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    padding: 0.7rem;
    font-family: ${props => props.theme.fontFamily.en_bold04};
    font-size: 16px;
    text-align: center;
    background-color: ${props => props.$isClicked ? props.theme.colors.red_salsa : props.theme.colors.light_silver};
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 10% 50%);
    border-radius: ${props => props.$borderLeft ? '5px 0 0 5px' : (props.$borderRight ? '0 5px 5px 0' : '0')};
    .ar & {
        font-family: ${props => props.theme.fontFamily.ar_extraBold};
        clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%, 90% 50%);
    }

    @media screen and (max-width: 800px) {
        font-size: 14px;
    }

    @media screen and (max-width: 460px) {
        text-align: right;
        .ar & {
            text-align: left;
        }
    }
`;