import { useMutation, useQuery } from "@tanstack/react-query";
import { getFreeShipping, getShipping, updateShipping } from "apis/shipping";
import useCartStore from "stores/cartStore";


export const useShipping = () => {
    const { data, isLoading, isError,error, refetch } = useQuery({
      queryKey: ["shipping"],
      queryFn: () => getShipping(),
      staleTime: 1000 * 60
    },);
    return { shipping: isError || isLoading? [] : data.shipping,
            userShippingInformation: isError || isLoading? {} : data.userShippingInformation,
            isLoading, isError,error, refetch}
};
export const useFreeShipping = () => {
  const cart = useCartStore((state)=> state.cart);
  const { data, isPending, isError,error, mutateAsync } = useMutation({
    mutationKey: ["dispatch"],
    mutationFn: () => getFreeShipping(cart),
  },);
  return { dispatch: isError || isPending? {} : data, isPending, isError,error, checkDispatch:mutateAsync}
};
export const useUpdateShipping = () => {
  const { data, error, isError, isPending, mutateAsync } = useMutation({
    mutationKey: ["update_shipping"],
    mutationFn: ({shippingInfo}) => updateShipping(shippingInfo),
  });
  return { isPending, error, isError, postShipping: mutateAsync}
};
  



