import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import './App.css'
import ProductListingPage from './pages/ProductListingPage'
import SingleProductPage from 'pages/SingleProductPage'
import Cart from 'pages/Cart'
import { useTranslation } from 'react-i18next';
import Checkout from 'pages/Checkout'
import PurchaseStatus from 'pages/PurchaseStatus'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  document.body.style.fontFamily = i18n.language == 'ar' ? 'Almarai-Regular' : 'Eina03-Regular';

  return (
    <Router>
      <div className={i18n.language === 'ar' ? 'ar' : ''}>
      <ToastContainer />
        <Routes>
          <Route path='/' element={<ProductListingPage />} />
          <Route path='/products/:url' element={<SingleProductPage />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/checkout' element={<Checkout/>} />
          <Route path='/purchase-status' element={<PurchaseStatus />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
