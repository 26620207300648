import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchProduct, fetchProducts, updateProduct } from "apis/products";
import useUserStore from "stores/userStore";


export const useProducts = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProducts(),
  });
  return { products: isError || isLoading? [] : data, isLoading}
};

export const useProduct = (productUrl) => {
    const { data, isLoading, isError, isRefetching } = useQuery({
      queryKey: ["product", productUrl],
      queryFn: () => fetchProduct(productUrl),
      refetchOnMount: false,
      
    });
    return { product: isError || isLoading || isRefetching? {}:data, isLoading, isError}
  };

export const useUpdateProductMutation = () => {
  return useMutation({
        mutationFn: updateProduct,
    });
};

