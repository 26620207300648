import axios from "utils/axios";


export const fetchProducts = async () => {
    try{
        const response = await axios(`shop/products`);
        return response.data.products;
    }catch(e){
        throw new Error('Failed to fetch products');
    }
};

export const fetchProduct = async (productUrl) => {
    try{
        const response = await axios(`shop/products/${productUrl}`);
        return response.data.product;
    }catch(e){
        throw new Error('Failed to fetch product data');
    }
  };
  
export const updateProduct = async (productId, updatedProductData) => {
    try{
        const response = await axios.post(`shop/products/${productId}`,updatedProductData,
        {
            headers: {
            'Content-Type': 'application/json',
            }
        },);
        return response.data;
    }catch(e){
        throw new Error('Failed to update product data');
    }
};