import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const availableLanguages = ['en', 'ar'];

const langDetectorOptions = {
  order: ["cookie"],
  lookupCookie: "language",
  caches: ["cookie"],
  excludeCacheFor: ["cimode"],
  checkWhitelist: true,
};

i18n
  .use(Backend) 

  .use(LanguageDetector) 

  .use(initReactI18next)

  .init({
    fallbackLng: 'en',
    debug: true,
    whitelist: availableLanguages,
    detection: langDetectorOptions,
    

    interpolation: {
      escapeValue: false
    },
  });
  

export default i18n;