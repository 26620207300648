import React, { useState } from 'react';
import styled from 'styled-components';
import Footer from 'components/GeneralLayout/Footer';
import Menu from 'components/GeneralLayout/Menu';
import TrustBox from 'components/GeneralLayout/TrustBox';
import { useNavigate } from 'react-router-dom';
import useCartStore from 'stores/cartStore';
import { Container, Content } from 'components/Common/Wrapper';
import ProductDetails from 'components/Products/ProductDetails';
import { IoCloseCircleOutline } from "react-icons/io5";
import { ModalCloseButton } from 'components/Common/Buttons';
import {  useProducts } from 'hooks/reactQuery/UseProductsQuery';
import ProductCard from 'components/Products/ProductCard';
import PopupModal from 'components/GeneralLayout/PopupModal';

// Styled components

const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  flex-grow: 1;
  margin:auto;
  direction: ltr;
  @media screen and (max-width: 300px){
    justify-content: center;
  }
  @media screen and (max-width: 500px){
    padding: 0px;
    justify-content: center;
  }
  gap: 10px;
`;


const ProductListingPage = () => {
const addToCart = useCartStore((state)=> state.addToCart);
const { products, isLoading} = useProducts();
const [viewSingleProduct, setViewSingleProduct] = useState("");
const navigate = useNavigate();

  const handleAddToCart = (product) => {
    addToCart(product)
  };
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
        setViewSingleProduct("")
    }
};
  return (
    <Container>
      {viewSingleProduct ?
      <PopupModal handleBackgroundClick={handleBackgroundClick}>
        <ModalCloseButton>
         <IoCloseCircleOutline fontSize="x-large" onClick={()=>{setViewSingleProduct("")}}/>
        </ModalCloseButton>
        <ProductDetails inModal productUrl={viewSingleProduct}  />
      </PopupModal>
        : null}
      <Menu/>
      <Content>
      <ProductListContainer>
        {products.map((product,ind) => (
          <ProductCard key={ind} product={product} handleAddToCart={handleAddToCart} setViewSingleProduct={setViewSingleProduct} navigate={navigate}/>
        ))}
      </ProductListContainer>
      <TrustBox/>
      </Content>
      <Footer/>
    </Container>
  );
};

export default ProductListingPage;
