import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import Theme from './utils/theme';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next';
import 'utils/i18n';


const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient({
  defaultOptions:{
    queries:{
      retry:0,
      refetchOnWindowFocus:false,
    }
  }
})
root.render(
      <QueryClientProvider client={queryClient}>
        <Theme >
        <App />
      </Theme>
      </QueryClientProvider>
)


