import styled from "styled-components";



export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 2; 
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ProductModal = styled.div`
    background-color: white;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 40%;
    position:relative;
    @media screen and (max-width: 1500px){
      width: ${props => props?.$width ? props?.$width : '60%'}
    }
    @media screen and (max-width: 1000px){
      width: 70%;
      max-height: 80%;
      overflow: auto;
    }
`;