import React, { useEffect } from 'react'
import { TrustBoxWrapper } from './style'
import useUserStore from 'stores/userStore';

const TrustBox = () => {
  const  lang = useUserStore((state) => state.lang);
  const ref = React.useRef(null)
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
      <TrustBoxWrapper
        ref={ref}
        className='trustpilot-widget'
        data-locale='en-GB'
        data-template-id='54ad5defc6454f065c28af8b'
        data-businessunit-id='5e6a1f55fa220b00010735a6'
        data-style-height='240px'
        data-style-width='100%'
        data-theme='light'
        data-tags={lang == 'en' ? 'English' : 'Arabic'}
        data-stars='4,5'
      >
        <a
          href='https://uk.trustpilot.com/review/basma.com'
          target='_blank'
          rel='noopener'
        >
          Trustpilot
        </a>
      </TrustBoxWrapper>
  )
}
export default TrustBox
