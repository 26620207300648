import useShippingForm from 'hooks/CustomHooks/useShippingForm';
import { useShipping, useUpdateShipping } from 'hooks/reactQuery/useShippingQuery';
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import 'react-phone-number-input/style.css';
import useCartStore from 'stores/cartStore';
import useUserStore from 'stores/userStore';
import { PayButton } from 'components/Common/Buttons';
import Cookies from 'js-cookie';
import { useAutomaticCoupons } from 'hooks/reactQuery/UseCouponsQuery';
import { toast } from 'react-toastify';
import i18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';

const Delivery = ({
    handleChangeTab,
}) => {
    const { t } = useTranslation();
    const shipping = useCartStore((state) => state.shipping);
    const updateShipping = useCartStore((state) => state.updateShipping);
    const country = Cookies.get('country');
    const { shipping: shippingCountries, userShippingInformation, isLoading } = useShipping();
    const { isPending: isPendingAutomaticCoupon, getAutomaticCoupons } = useAutomaticCoupons();
    const { isPending, error, isError, postShipping } = useUpdateShipping();
    const guest = useUserStore((state) => state.guest);
    const { PhoneComponent, handleSubmit,setValue, errors, fullNameRegister, emailRegister, addressRegister, addressRegister2, addressRegister3, cityRegister, phoneRegister,postalCodeRegister } = useShippingForm();
    const cart = useCartStore((state) => state.cart);
    const updateCart = useCartStore((state) => state.updateCart);
    const navigate = useNavigate();

    const countries = useMemo(() => {
        return shippingCountries.map(item => ({
            label: item.country,
            amount: item.amount,
            value: item.code
        }));
    }, [shippingCountries])
    const removeGuestItems = () =>{
        if (!guest) {
            return;
        }
        let newCart = cart.filter(item =>item.guest_product)
        if(newCart.length == 0){
            updateCart(newCart)
            toast(t('guest-error-message'),{type:"warning",autoClose: 10000, rtl:i18n.language == 'ar'? true : false});
            navigate("/")
        }else{
            if(newCart.length != cart.length){
                updateCart(newCart)
                toast(t('guest-error-message'),{type:"warning",autoClose: 10000, rtl:i18n.language == 'ar'? true : false});
            }
        }
    }

    useEffect(() => {
        setValue('email', userShippingInformation?.email ?? '');
        if (userShippingInformation?.full_name) {
            if (!shipping?.label) {
                let index = shippingCountries.findIndex((shippingCountry) => shippingCountry.country == userShippingInformation.country)
                if (index != -1) {
                    updateShipping({ label: shippingCountries[index].country, amount: shippingCountries[index].amount, value: shippingCountries[index].code })
                }
            }
            setValue('full_name', userShippingInformation?.full_name ?? '');
            setValue('address', userShippingInformation?.address ?? '');
            setValue('address_2', userShippingInformation?.address_2 ?? '');
            setValue('address_3', userShippingInformation?.address_3 ?? '');
            setValue('postal_code', userShippingInformation?.postal_code ?? '');
            setValue('city', userShippingInformation?.city ?? '');
            setValue('phone', userShippingInformation?.phone ?? '');
            getAutomaticCoupons();
        }

    }, [userShippingInformation?.full_name])

    const updateShippingInfo = (data) => {
        let shippingInfo = {
            shipping_address: data.address,
            shipping_address_2: data.address_2,
            shipping_address_3: data.address_3,
            shipping_city: data.city,
            shipping_country: shipping.label,
            shipping_email: data.email,
            shipping_full_name: data.full_name,
            shipping_phone: data.phone,
            shipping_postal_code: data.postal_code,
        }
        postShipping({shippingInfo},
            {
                onSuccess: async (data) => {
                    removeGuestItems()
                    handleChangeTab("payment")
                }
            }
        )

    }

    return (
        <>
            <form className='checkout-form' onSubmit={handleSubmit((data) => updateShippingInfo(data))} >
                <ReactSelect
                    value={shipping?.label ? shipping : null}
                    onChange={updateShipping}
                    options={countries}
                    placeholder={t('country-region')}
                    classNamePrefix='country-select'
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    required
                />
                <div className='checkout-input-divider'>
                        <input
                            type="text"
                            className='checkout-input'
                            name="full_name"
                            {...fullNameRegister}
                            placeholder={t('full-name')}
                        />
                        <input
                            type="text"
                            className='checkout-input'
                            name="email"
                            disabled={!guest}
                            {...emailRegister}
                            placeholder={t('email-address')}
                        />
                </div>
                <div className='checkout-input-divider'>
                    {
                        errors?.email ?<p className='form-error'>{errors?.email?.message}</p>:
                        errors?.full_name ?<p className='form-error'>{errors?.full_name?.message}</p>: 
                        null
                    }
                </div>
                <input
                    type="text"
                    className='checkout-input'
                    name="address"
                    {...addressRegister}
                    placeholder={t('address')}
                />
                {errors?.address ?<p className='form-error'>{errors?.address?.message}</p>: null}
                <input
                    type="text"
                    className='checkout-input'
                    name="address_2"
                    {...addressRegister2}
                    placeholder={t('address-2')}
                />
                {errors?.address_2 ?<p className='form-error'>{errors?.address_2?.message}</p>: null}
                <input
                    type="text"
                    className='checkout-input'
                    name="address_3"
                    {...addressRegister3}
                    placeholder={t('address-3')}
                />
                {errors?.address_3 ?<p className='form-error'>{errors?.address_3?.message}</p>: null}
                <div className='checkout-input-divider'>
                    <input
                        type="text"
                        className='checkout-input'
                        name="city"
                        {...cityRegister}
                        placeholder={t('city')}
                    />
                    <input
                        type="text"
                        className='checkout-input'
                        name="postal_code"
                        {...postalCodeRegister}
                        placeholder={t('postal-code')}
                    />
                </div>
                <div className='checkout-input-divider'>
                    {errors?.city ?<p className='form-error'>{errors?.city?.message}</p>: null}
                </div>
                <PhoneComponent country={country} />
                {/* <PhoneInput
                    className='checkout-phone-input'
                    defaultCountry={country}
                    countries={globalCountries}
                    placeholder={t('phone')}
                    {...phoneRegister}
                />  */}
                {errors?.phone ?<p className='form-error'>{errors?.phone?.message}</p>: null}
                <PayButton disabled={isPending || isLoading} $padding={"15px"} $width={"100%"} $borderRadius={'5px'} $margin={'2em auto auto auto'} >{t('update-shipping')}</PayButton>
            </form>
        </>
    )
}

export default Delivery