import styled from 'styled-components'

export const AddToCartButton = styled.button`
  transition: all .3s ease;
  width: 140px;
  height: 40px;
  background: ${props => props.theme.colors.red_salsa};
  justify-content: center;
  font-size: 14px;
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  border: none;
  padding:0;
  cursor: pointer;
  font-family: ${props => props.theme.fontFamily.en_semiBold};
  &:hover{
    background-color: ${props => props.theme.colors.light_red_salsa};
  }
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
`
export const Button = styled.button`
  transition: all .3s ease;
  height: ${props => props.$height ? props.$height : '60px'};
  background: ${props => props.$background || props.theme.colors.red_salsa};
  padding: ${props => props.$padding || 'initial'};
  justify-content: center;
  font-size: 14px;
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  border: none;
  border-radius: ${props => props.$borderRadius || 'initial'};
  width: ${props => props.$width || 'initial'};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    background: ${props =>  props.$backgroundHover || props.theme.colors.light_red_salsa};
  };
  margin : auto;
  font-family: ${props => props.theme.fontFamily.en_semiBold};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
  @media screen and (max-width: 600px) {
    width: ${props => props.$widthMobile ? props.$widthMobile : props.$width || 'initial'};
  }
`

export const PayButton = styled.button`
  transition: all .3s ease;
  background: ${props => props.theme.colors.red_salsa};
  padding: ${props => props.$padding || 'initial'};
  justify-content: center;
  font-size: 14px;
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  border: none;
  border-radius: ${props => props.$borderRadius || 'initial'};
  width: ${props => props.$width || 'initial'};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    background: ${props =>  props.theme.colors.red_salsa};
  };
  margin: ${props => props.$margin || 'auto'};
  font-family:${props => props.theme.fontFamily.en_semiBold};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
  &:disabled {
    background-color: #e8e8e8;
    cursor: not-allowed;
  }
`

export const ModalCloseButton = styled.div`
    position: absolute;
    top: 0%;
    right: 0%;
    cursor: pointer;

`