import styled from 'styled-components'

export const FooterMainDiv = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 80px;
  @media screen and (max-width: 767px){
    width: 90%;
    margin: auto;
  }
`

export const MainContent = styled.div`
  gap: 15px 10%;
  display: flex;
  @media screen and (max-width: 767px){
    flex-direction: column-reverse; 
  }
`
export const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  word-break: normal !important;
  width: 20%;
  @media screen and (min-width: 1700px){
    width: 17%;
  }
  @media screen and (max-width: 767px){
    width: 40%;
    margin: auto;
  }
  @media screen and (max-width: 500px){
    width: 60%;
  }
  @media screen and (max-width: 400px){
    width: 80%;
    margin: auto;
  }
`
export const ContactSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 10px;
`
export const SectionWithTitle = styled.div`
  flex-grow: 1;
`
export const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const LinkSectionTitle = styled.span`
  font-family: ${props => props.theme.fontFamily.en_bold03};
  font-size: 15px;
  line-height: 30px;
  color: ${props => props.theme.colors.onyx};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
`
export const SubContent = styled.div`
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  @media screen and (max-width: 767px){
    justify-content: center;
    gap:10px;
  }
  @media screen and (max-width: 500px){
    justify-content: center;
  }
`
const FooterSocialLinksMenu = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 20px;
  width: fit-content;
`
export const PaymentMethods = styled(FooterSocialLinksMenu)`
  align-items: center;
`;
export const Privacy = styled(FooterSocialLinksMenu)`
`;